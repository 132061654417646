import React, { useEffect } from "react";
import { useDaybreakTranslation } from "../../hooks/useDaybreakTranslation";
import { trackEvent } from "client/amplitudeHelper";
import en from "./ParentWelcomePacket.en.json";
import es from "./ParentWelcomePacket.es.json";

import {
  CompleteIntakeH2,
  CompleteIntakeTealButton,
  Description,
  HeaderSection,
  H1,
  ScheduleSection,
  ScheduleButtonSection,
} from "./elements";

export type CompleteIntakeSectionProps = { link: string };
const CompleteIntakeSection: React.FC<CompleteIntakeSectionProps> = ({
  link,
}) => {
  const { t } = useDaybreakTranslation("completeIntake", { en, es });

  useEffect(() => {
    trackEvent("PWP:viewCompleteAsyncIntake");
  }, []);

  return (
    <ScheduleSection>
      <HeaderSection>
        <H1>{t("completeIntake.title")}</H1>
        <CompleteIntakeH2>{t("completeIntake.heading")}</CompleteIntakeH2>
        <Description>{t("completeIntake.description")}</Description>
      </HeaderSection>
      <ScheduleButtonSection>
        <CompleteIntakeTealButton
          onClick={() => {
            trackEvent("PWP:clickCompleteAsyncIntake");
            window.location.href = link;
          }}
        >
          {t("completeIntake.title")}
        </CompleteIntakeTealButton>
      </ScheduleButtonSection>
    </ScheduleSection>
  );
};

export default CompleteIntakeSection;
