import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useApi } from "../api/apiContext";
import { datadogRum } from "../client/datadogHelper";

/**
 * Gets the currently-selected teen as a User object, if one can be found.  If
 * not, it tries to select one.
 *
 * @returns {
 *  teen: a teen User object, or undefined if not found,
 *  isLoadingTeen: a boolean indicating whether the teen is still loading
 * }
 */
export const useSelectedTeen = ({ redirectIfNone = true } = {}) => {
  const api = useApi();
  const selectedTeenId = useParams<{ userExternalId?: string }>()
    .userExternalId;

  const { data: { data: me } = {} } = api.useGetMe();
  const {
    data: { data: teen } = {},
    isLoading: isLoadingTeen,
  } = api.useGetUser({
    id: selectedTeenId ?? "",
    options: { enabled: selectedTeenId != null },
  });
  const history = useHistory();

  // If we didn't find the currently selected teen (because there is no current
  // selection, or the current selection is not a teen you have access to
  // anymore), fetch all available teens and grab the first one.
  const teenNotFound = !isLoadingTeen && !teen;
  const {
    data: { data: users } = {},
    isLoading: isLoadingUsers,
    isError: isUsersError,
  } = api.useGetUsers({
    options: { enabled: teenNotFound },
  });
  const teens = users && me && users.filter((user) => user.id !== me.id);

  useEffect(() => {
    if (!redirectIfNone) return;

    // Don't bother doing anything fancy until at least the current user is loaded.
    if (!me) return;

    const firstTeen = teens && teens[0];
    console.log("useSelectedTeen effect", {
      firstTeen: firstTeen?.id ?? "nil",
      selectedTeenId: selectedTeenId ?? "nil",
      teenNotFound: teenNotFound ?? "nil",
      me: me?.id ?? "nil",
    });
    const redirectToFirstTeen = () => {
      console.log("redirecting to first teen, ", firstTeen);
      history.push(
        me?.userKind === "counselor"
          ? `/clinician/${firstTeen?.id}/dashboard`
          : `/${firstTeen?.id}/dashboard`
      );
    };
    const redirectToNoChildrenErrorPage = () => {
      console.warn("redirecting to no children error page", {
        isUsersError,
      });
      datadogRum.addError(
        new Error(`Redirecting the the no-children-found error page.`)
      );
      if (!isUsersError) {
        history.push("/no-children-found");
      }
    };

    // If there was a teen id in the url...
    if (selectedTeenId) {
      // If the teen from the url wasn't found...
      if (teenNotFound) {
        // And if there is at least one visible teen
        if (firstTeen) {
          // Redirect to the first visible teen
          redirectToFirstTeen();
        } else {
          // No visible teens, redirect to an error (unless something went wrong with the request).
          redirectToNoChildrenErrorPage();
        }
      } else {
        // This is the happy case - the selected teen was found.  Yay!
      }
    } else {
      // There's no selected teen.  We probably just finished logging in (or
      // something went wrong).

      // If there is at least one visible teen, redirect to that.
      if (firstTeen) {
        // Redirect to first teen.
        redirectToFirstTeen();
      } else if (!isLoadingUsers) {
        // There's no visible teens - redirect to error unless something went wrong with the request.
        redirectToNoChildrenErrorPage();
      }
    }
  }, [
    history,
    isLoadingUsers,
    isUsersError,
    me,
    redirectIfNone,
    selectedTeenId,
    teenNotFound,
    teens,
  ]);

  return { teen, isLoadingTeen };
};
