import styled from "styled-components/macro";
import { isDaybreakError } from "../../api/apiClient";
import { useApi } from "../../api/apiContext";
import {
  SurveyMonkeyResponse,
  SurveyMonkeyResponse_Answer,
} from "../../api/queries/surveys";
import { datadogRum } from "../../client/datadogHelper";
import { themeMinWidth } from "../../app/theme";
import Survey from "../../lib/models/survey";
import { CenteredLoading } from "../Loading/Loading";
import MultipleChoiceQuestion from "../Quiz/MultipleChoiceQuestion/MultipleChoiceQuestion";
import QuizFrame from "../Quiz/QuizFrame/QuizFrame";
import { isClosedScreenerStatusError } from "../MultipartQuiz/MultipartQuiz";
import { Form } from "../../api/queries/forms";
import { useEffect } from "react";

export type QuizPartProps = {
  questionIndex: number;
  form: Form;
  onAnswer: (partFinished: boolean) => void;
  onBack: () => void;
  onSaveError: (error: unknown) => void;
  featureApiKey: string;
};

const QuizContainer = styled.div`
  min-height: 400px;
  ${themeMinWidth("small")} {
    padding: 0 44px 60px 44px;
  }
`;

export type QuizPartWithDataProps = {
  answerHandler: (answers: SurveyMonkeyResponse_Answer[]) => void;
  survey: Survey;
  loading: boolean;
} & Pick<QuizPartProps, "onBack" | "questionIndex">;

export const QuizPartWithData: React.FC<QuizPartWithDataProps> = ({
  survey,
  questionIndex,
  answerHandler,
  onBack,
  loading,
}) => {
  if (loading) {
    return (
      <QuizContainer>
        <CenteredLoading />
      </QuizContainer>
    );
  }

  const { question, promptText } = survey.questionInfo(questionIndex);

  if (!question) {
    return (
      <QuizContainer>
        <CenteredLoading />
      </QuizContainer>
    );
  }

  // Figure out which of the supported question types this question is.  Only
  // one type at the moment.
  let QuestionElement = null;
  switch (question.family) {
    case "single_choice":
      QuestionElement = MultipleChoiceQuestion;
      break;
    default:
      throw new Error(`Unknown question type '${question}'`);
  }

  // Don't show the back button if this is the first question.
  const onBackIfPossible = questionIndex > 0 ? onBack : undefined;

  // Render the question.
  return (
    <QuizContainer>
      <QuizFrame currentQuestion={questionIndex + 1} onBack={onBackIfPossible}>
        <QuestionElement
          prompt={promptText}
          question={question.headings[0].heading}
          answers={question.answers}
          onAnswer={answerHandler}
        />
      </QuizFrame>
    </QuizContainer>
  );
};

// This component understands a single quiz phase (ie one surveymonkey survey),
// and can render a specific question within it.  It does *not* know about page
// routing or other surveys / quiz phases.
const QuizPart: React.FC<QuizPartProps> = ({
  form,
  questionIndex,
  onAnswer,
  onBack,
  onSaveError,
  featureApiKey,
}) => {
  const survey = new Survey(form);

  const { question } = survey.questionInfo(questionIndex);
  const totalUserFacingQuestions = survey.questionsByType().userFacingQuestions
    .length;

  const {
    mutate: postAnsweredFormResponse,
    isLoading: postingAnsweredFormResponse,
    isSuccess: postAnsweredFormSucceeded,
  } = useApi().usePostAnsweredFormByFeature({
    options: {
      onError: (error) => {
        datadogRum.addError(new Error("Error saving survey response"), {
          surveryError: error,
        });
        onSaveError(error);
      },
      retry: (failureCount, error) => {
        // Don't retry this specific error so we can handle it explicitly before
        // it goes away.
        if (
          isDaybreakError(error) &&
          error.error_type === "local_store_expired"
        ) {
          return false;
        }
        if (isClosedScreenerStatusError(error)) {
          return false;
        }

        // Default to the normal "retry 3 times" behavior
        return failureCount < 3;
      },
    },
  });

  const isLastQuestion = questionIndex === totalUserFacingQuestions - 1;

  // When a user selects an answer, save that answer to our api as a partial
  // response object.  SurveyMonkey will intelligently merge that response
  // object into the existing response if one exists.  Our backend will handle
  // managing reponse ids for us.
  const answerHandler = (answers: SurveyMonkeyResponse_Answer[]) => {
    if (!question) {
      datadogRum.addError(
        new Error(
          `Tried to submit question number '${questionIndex}' with no matching question in survey ${form?.id}`
        )
      );
      return;
    }

    // Construct a partial SurveyMonkey Response object for saving.
    const response_status = isLastQuestion ? "completed" : "partial";
    const response: SurveyMonkeyResponse = {
      pages: [
        {
          id: question.page.id,
          questions: [
            {
              id: question.id,
              answers: answers,
            },
          ],
        },
      ],
      response_status,
    };

    postAnsweredFormResponse({ featureApiKey, formData: response });
  };

  useEffect(() => {
    if (isLastQuestion) {
      if (postAnsweredFormSucceeded) {
        // If we're submitting the final answer for this quiz part, wait until
        // we've finished saving it before we move on to make sure that the
        // survey gets completed.
        onAnswer(isLastQuestion);
      }
    } else {
      if (postingAnsweredFormResponse) {
        // If we're submitting any answer except the final one for this part,
        // optimistically continue on to the next question while the saving
        // happens in the background.
        onAnswer(isLastQuestion);
      }
    }
  }, [
    isLastQuestion,
    onAnswer,
    postAnsweredFormSucceeded,
    postingAnsweredFormResponse,
  ]);

  if (question == null) {
    // We have a transitory state here where, between survey sections, we'll
    // occasionally run into a missing question for one render cycle or so.
    // This shouldn't happen, but it's not user-facing. Investigation is here:
    // https://www.notion.so/daybreakhealth/Question-Not-Found-errors-ccc4c70ed2d84715b2e9c42c77720f0a
    console.log(
      `Question number '${questionIndex}' not found, showing survey ${form?.id}`
    );

    return (
      <QuizContainer>
        <CenteredLoading />
      </QuizContainer>
    );
  }

  return (
    <QuizPartWithData
      survey={survey}
      questionIndex={questionIndex}
      answerHandler={answerHandler}
      onBack={onBack}
      loading={postingAnsweredFormResponse || postAnsweredFormSucceeded}
    />
  );
};

export default QuizPart;
