import {
  GetIdTokenClaimsOptions,
  GetTokenSilentlyOptions,
  IdToken,
  useAuth0,
} from "@auth0/auth0-react";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import qs from "qs";
import { datadogRum } from "../client/datadogHelper";
import guestUserTokenStorage from "../lib/guestUserTokenStorage";
import { AUTH0_AUDIENCE, AUTH0_REDIRECT_URI } from "./DaybreakAuthProvider";

/**
 * How to add a new api query hook:
 * https://www.notion.so/daybreakhealth/Access-api-data-with-a-query-hook-1677db549b834b50a6b568c4d15325cd
 */

const baseURL = process.env.REACT_APP_API_BASE_URL;
if (!baseURL) {
  console.error("Missing base url");
}

export type DaybreakError = {
  error: string;
  error_type: string;
};

export type DaybreakErrorV4 = {
  error: string;
  errorType: string;
  errorData?: any;
};
// A type guard to check whether an object is a daybreak error.  After running
// this check, typescript will know that your object is a DaybreakError.  Eg:
//
// const foo = {};
// if (isDaybreakError(foo)) {
//   console.log(foo.error_type) // doesn't type error
// }
export type RequestError = AxiosError | DaybreakError | DaybreakErrorV4;

export const isDaybreakError = (obj: any): obj is DaybreakError => {
  return obj != null ? "error_type" in obj : false;
};

export const isDaybreakErrorV4 = (obj: any): obj is DaybreakErrorV4 => {
  return obj != null ? "errorType" in obj : false;
};
export const isAxiosError = (obj: any): obj is AxiosError => {
  return obj != null ? "response" in obj : false;
};

const requestInterceptor = [
  (config: AxiosRequestConfig) => {
    // Add guest token to outgoing requests
    const tokenData = guestUserTokenStorage.get();
    config.headers = config.headers ?? {};
    if (tokenData) {
      config.headers["Guest-User-Token"] = tokenData.token;
    }

    // Allow the backend to know which client it's talking to
    config.headers["Daybreak-Application"] = "web_frontend";

    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  },
];

const responseInterceptor = [
  (response: AxiosResponse) => {
    const headers = response.headers;

    // Receive guest token from incoming responses
    const token = headers["guest-user-token"];
    if (token) {
      guestUserTokenStorage.set({ token });
    }

    return response;
  },
  (error: any) => {
    // Should we report this error to DataDog?  Maybe!
    if (shouldReportError(error)) {
      datadogRum.addError(error, {
        response: error?.response ?? "no response object",
      });
    }
    // If this looks like one of our messages (rather than, like, a rails-level 404)
    if (error?.response?.data?.errors || error?.response?.data?.error) {
      if (error.response.status === 401) {
        console.log("Clearing guest user token.");
        // If we got a 401, our guest token is busted so clear it from local storage.
        guestUserTokenStorage.clear();
      }
    }
    return Promise.reject(error);
  },
];

const shouldReportError = (error: any) => {
  if (!error || !error.response) {
    return true;
  }

  // For now, only report 500s.  Everything else is too situational to default to reporting.
  if (error.response.status >= 500) {
    return true;
  }

  return false;
};

// Create an axios api client for making api requests to the backend.  Don't use
// this directly, since it doesn't handle auth.  Use the `useApiClient` hook
// instead.
const buildApiClient = () => {
  const apiClient = axios.create({
    baseURL: baseURL,
    // This causes axios to send cookies along with requests.  This is necessary
    // for rails sessions to work.
    withCredentials: true,
  });
  apiClient.interceptors.request.use(...requestInterceptor);
  apiClient.interceptors.response.use(...responseInterceptor);
  return apiClient;
};

// Intercepts outgoing requests to our api and asks Auth0 for an access token.
// Once it receives one, it sends that token on along with the request to our
// backend.
// You'd think this would slow down every request, but Auth0 actually caches
// this token for us after the first time you do this on each page load.  So for
// a single-page app like ours, you should only hit this once each session
// unless you manually refresh the page.
const authInterceptor = (
  getAccessTokenSilently: (
    options?: GetTokenSilentlyOptions | undefined
  ) => Promise<string>,
  getIdTokenClaims: (
    options?: GetIdTokenClaimsOptions | undefined
  ) => Promise<IdToken>
) => {
  return async (config: AxiosRequestConfig) => {
    config.headers = config.headers ?? {};
    const token = await getAccessTokenSilently({
      // There's no redirect involved in this step, but the docs require this anyway:
      // https://auth0.github.io/auth0-react/interfaces/index.gettokensilentlyoptions.html#redirect_uri
      redirect_uri: AUTH0_REDIRECT_URI,
      audience: AUTH0_AUDIENCE,
    });
    const idTokenClaims = await getIdTokenClaims({
      audience: AUTH0_AUDIENCE,
    });
    config.headers["Authorization"] = "Bearer " + token;
    config.headers["idToken"] = idTokenClaims.__raw;
    return config;
  };
};

// Get yourself an apiClient with this handy hook!  In general, this should only
// be used in *other* hooks - eg a UI component uses the useGetUser() hook,
// which in turn uses useApiClient().  Pass in `auth: false` if you're accessing a
// guest-user endpoint
type ApiClientOptions = { auth?: boolean };
export const useApiClient = (options: ApiClientOptions = {}) => {
  const auth = options.auth ?? true;
  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();

  const client = buildApiClient();

  if (auth) {
    client.interceptors.request.use(
      authInterceptor(getAccessTokenSilently, getIdTokenClaims)
    );
  }

  // Axios doesn't do a great job serializing complex params (eg nested
  // objects).  This seems to be a known issue with a known workaround:
  // https://github.com/axios/axios/issues/738
  client.interceptors.request.use((config) => {
    config.paramsSerializer = (params) => {
      return qs.stringify(params, {
        arrayFormat: "brackets",
        encode: false,
      });
    };

    return config;
  });

  return client;
};
