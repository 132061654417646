import { datadogRum } from "../../client/datadogHelper";
import {
  createUseApiMutation,
  createUseApiQuery,
  optionalParams,
  PageMetaTypeV1,
} from "./queryHelpers";
import { Referral, ReferralV1 } from "./referrals";
import * as Yup from "yup";
import { OrganizationSite } from "./organizationSites";
import { Counselor } from "./sessions";
import { DateTime } from "luxon";
import { identifyUser } from "client/amplitudeHelper";

export type PostClaimOrganizationMemberVariables = {
  organizationApiKey: string;
  studentId: string;
  dob: Date;
  screenerLanguage: string;
};
export type PostClaimOrganizationMemberResponse = {
  id: string;
  type: "claimOrganizationMemberAttempt";
  successful: boolean;
  userFeatures: string[];
  userId: string;
};
export type LatestReferralStatuses =
  // Referral statues:
  | "referred"
  | "discharged"
  // Intake statuses:
  | "scheduling"
  | "scheduled"
  | "cancelled"
  | "completed"
  | "abandoned"
  | "no_show"
  | "matched"
  | "rescheduled"
  | "in_progress"
  | "accepted_for_services";

// Note: this endpoint currently assumes the data we're sending is always
// studentId/dob.  This will change in the future, probably on a per-org basis,
// and we'll need to adjust for that then.
export const usePostClaimOrganizationMember = createUseApiMutation<
  PostClaimOrganizationMemberResponse,
  PostClaimOrganizationMemberVariables
>({
  requestBuilder: (mutationFunctionArguments) => ({
    method: "post",
    url: `/v1/organizations/${mutationFunctionArguments.organizationApiKey}/claim_organization_member`,
    data: {
      unique_student_id: mutationFunctionArguments.studentId,

      // Grab just the date of the dob date object (in iso format)
      date_of_birth: mutationFunctionArguments.dob.toISOString().slice(0, 10),
      screener_language: mutationFunctionArguments.screenerLanguage,
    },
  }),
  auth: false,
  defaultMutationOptions: {
    retry: false,
    onSuccess: (data, _variables, _context) => {
      identifyUser(data.data.userId);
      datadogRum.setUser({
        id: data.data.userId,
      });
    },
  },
});

// This is the shape of the organization member records returned by the v4 org
// member serializers in the backend.
export type OrganizationMember = {
  id: string;
  organizationSiteId: string;
  externalUserId?: string;
  missingRoi: boolean | undefined;
  systemOfCare: "not_applicable" | "theranest" | "salesforce_EHR";
  isAdult: boolean;
  fullName: string;
  nickname: string;
  uniqueStudentId: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  studentEmail: string;
  gender: string;
  gradeLevel: number;
  frpl: string;
  preferredLanguage: string;
  preferredPronoun: string;
  referralJourneyStatus: Referral["referralJourneyStatus"];
  clientJourneyStatus:
    | "Client_Referral"
    | "Discharged"
    | "Match_in_Progress"
    | "Ongoing_Care";
  futureMeets: Object[];
  futureTheranestAppointments: Object[];
  referrals: Referral[];
  latestReferral: Referral | null;
  careProvider: Counselor | null;
};

// This is the shape of the organization member records returned by the v1 and
// v2 org member serializers in the backend.
export type OrganizationMemberV1 = {
  id: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  uniqueStudentId: string;
  studentEmail: string;
  gender: string;
  gradeLevel: number;
  nextSessionDatetime: string;
  schoolName?: string;
  externalUserId?: string;
  missingRoi: boolean | undefined;
  referralJourneyStatus: Referral["referralJourneyStatus"];
  clientJourneyStatus: OrganizationMember["clientJourneyStatus"];
  referral?: {
    id: string;
    externalId: string;
    insuranceStatus: ReferralV1["insuranceStatus"];
    insuranceRequestUrl: string;
    referredAt: number;
    referralJourneyStatus: Referral["referralJourneyStatus"];
    partialReferral?: {
      guardianFirstName: string;
      guardianLastName: string;
      guardianEmail: string;
    };
  };
  intake?: null | {
    academicBehaviorAndSchoolIssues: string;
    additionalNeeds: string;
    counselingGoals: string;
    primaryNeed: string;
    presentingProblem: string;
    startTime: number | null;
  };
  discharge?: null | {
    clinicalRecommendation: string;
    progressOnTreatmentGoals: string;
    reportToSchool: string;
    schoolTreatmentGoals: string;
  };
  careProvider: {
    id: string;
    fullName: string;
    firstName?: string;
    lastName?: string;
    zoomLink?: string;
    email?: string;
  };
  referredBy?: {
    firstName?: string;
    lastName?: string;
    email?: string;
  };
  welcomePacket?: {
    dueDate: number;
    packetComplete: boolean;
    welcomePacketUrl?: string | null;
    packetSignedAt?: number | null;
    packetCompletedAt?: number | null;
    ayncIntakeQuestionnaireCompleted?: boolean;
    asyncIntakeUniqueLink?: string | null;
    intakeSchedulingLink?: string | null;
    intakeType: string | null;
    insuranceStatus: string | null;
    intakeStatus: string | null;
    insuranceRequired: boolean | null;
    status: string;
  };

  latestReferralStatus: LatestReferralStatuses;
};

export type PostOrganizationMemberParams = {
  id: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  uniqueStudentId: string;
  studentEmail: string;
  gender: string;
  otherGender?: string;
  gradeLevel: string;
  schoolName?: string;
  organizationSiteId?: string;
  apiKey: string;
};

export const genderOptions = ["male", "female", "other"];

export const getFormikOrganizationMemberConfig = (
  sites: OrganizationSite[]
) => {
  const thirtyYearsAgo = DateTime.now().minus({ years: 30 }).toJSDate();
  return {
    initialValues: {
      id: "",
      firstName: "",
      lastName: "",
      dateOfBirth: "",
      uniqueStudentId: "",
      studentEmail: "",
      gender: "",
      otherGender: "",
      gradeLevel: "",
      organizationSiteId: "",
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required("Please provide a first name"),
      lastName: Yup.string().required("Please provide a last name"),
      dateOfBirth: Yup.date()
        .min(thirtyYearsAgo, "Student must be less than 30 years old")
        .max(new Date(), "Can't be born in the future")
        .required("Please provide a birthdate"),
      uniqueStudentId: Yup.string().required("Please provide a student ID"),
      studentEmail: Yup.string()
        .email("Must be a valid email")
        .required("Please provide a valid email"),
      gender: Yup.string()
        .oneOf(genderOptions)
        .required("Please provide a gender"),
      otherGender: Yup.string().optional(),
      gradeLevel: Yup.number()
        .required("Please provide an grade")
        .min(0, "Grade must be 0 - 12")
        .integer(),
      organizationSiteId: Yup.string()
        .oneOf(sites.map((site) => site.id))
        .required("School is required"),
    }),
  };
};

export const usePostOrganizationMember = createUseApiMutation<
  PostOrganizationMemberParams,
  PostOrganizationMemberParams
>({
  requestBuilder: (mutationFunctionArguments) => ({
    method: "post",
    url: `/v4/organization_members`,
    data: {
      organization_member: {
        first_name: mutationFunctionArguments.firstName,
        last_name: mutationFunctionArguments.lastName,
        date_of_birth: mutationFunctionArguments.dateOfBirth,
        unique_student_id: mutationFunctionArguments.uniqueStudentId,
        student_email: mutationFunctionArguments.studentEmail,
        gender:
          mutationFunctionArguments.gender === "other" &&
          mutationFunctionArguments.otherGender
            ? mutationFunctionArguments.otherGender
            : mutationFunctionArguments.gender,
        grade_level: mutationFunctionArguments.gradeLevel,
        organization_site_id: mutationFunctionArguments.organizationSiteId,
        organization_api_key: mutationFunctionArguments.apiKey,
      },
    },
  }),
  auth: true,
  defaultMutationOptions: {
    retry: false,
  },
});

export type GetOrganizationMembersParams = {
  organizationSiteId?: string;
  externalIds?: string[];
  gradeLevel?: number;
  q?: string;
  orderBy?: string;
  page?: number;
  perPage?: number;
  include?: string[];
  fields?: Record<string, string[]>;
  referral_status?: ReferralV1["status"][];
  needInsuranceInfo?: boolean;
};
export const useGetOrganizationMembers = createUseApiQuery<
  OrganizationMemberV1[],
  GetOrganizationMembersParams,
  PageMetaTypeV1
>({
  queryBuilder: ({
    organizationSiteId,
    externalIds,
    gradeLevel,
    q,
    orderBy,
    page,
    perPage,
    include,
    fields,
    referral_status,
    needInsuranceInfo,
  }) => {
    let params = optionalParams({
      organization_site_id: organizationSiteId,
      external_ids: externalIds,
      grade_level: gradeLevel,
      q,
      order_by: orderBy,
      page,
      per_page: perPage,
      include,
      fields,
      referral_status,
      need_insurance_info: needInsuranceInfo,
    });
    params.fields = {
      ...fields,
      organization_members: [
        "latest_referral_status",
        ...(fields ? fields.organization_members : []),
      ],
    };
    return {
      cacheKey: ["organizationMembers", params],
      url: `/organization_members/v1`,
      config: { params },
    };
  },
});

const organizationMemberQueryKey = (organizationMemberExternalId: string) => {
  return ["organizationMembers", organizationMemberExternalId];
};
export const useGetOrganizationMember = createUseApiQuery<
  OrganizationMemberV1,
  {
    externalId: string;
  },
  PageMetaTypeV1
>({
  queryBuilder: ({ externalId }) => {
    return {
      cacheKey: ["organizationMembers", organizationMemberQueryKey(externalId)],
      url: `/organization_members/v1/${externalId}`,
    };
  },
});

export const useGetOrganizationMemberV4 = createUseApiQuery<
  OrganizationMember,
  {
    externalId: string;
  },
  PageMetaTypeV1
>({
  queryBuilder: ({ externalId }) => {
    const params = optionalParams({
      include: "future_meets,referrals",
    });

    return {
      cacheKey: ["organizationMembers", organizationMemberQueryKey(externalId)],
      url: `v4/organization_members/${externalId}`,
      config: { params },
    };
  },
});
