import React from "react";
import styled from "styled-components/macro";
import { useApi } from "../../../api/apiContext";
import { User } from "../../../api/queries/users";
import { datadogRum } from "../../../client/datadogHelper";
import { themeColor } from "../../../app/theme";
import TextLink from "../../../components/TextLink/TextLink";
import { supportEmail } from "../../../constants";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
`;
const H1 = styled.h1`
  font-size: 32px;
  color: ${themeColor("teal")};
  font-weight: 500;
`;
const P = styled.p`
  max-width: 600px;
`;

type ContentProps = { me: User };
const Content: React.FC<ContentProps> = ({ me }) => {
  const supportMailTo = `mailto:${supportEmail}`;
  switch (me.userKind) {
    // Unfortunately, because we infer "you are a parent" from "you have 1+
    // children," there's an edge case to this page's error case where you are a
    // parent with no attached children and therefore your userKind is "teen."
    // TODO: fix that.
    case "parent":
    case "teen":
      return (
        <div>
          <H1>We're Sorry</H1>
          <P>
            No children were found. If your child is receiving support from
            Daybreak and you think they should be here, please contact{" "}
            <TextLink href={supportMailTo}>our support staff</TextLink>.
          </P>
          <P>
            <i>
              If your child is 18 or older, you're not able to access their
              records through the parent dashboard.
            </i>
          </P>
        </div>
      );
    case "counselor":
    case "psychiatrist":
      return (
        <div>
          <H1>No clients found</H1>
          <P>
            No active clients that you're currently supporting were found. If
            you think this is in error, let your supervisor know so we can get
            this fixed!
          </P>
        </div>
      );
    default:
      datadogRum.addError(
        new Error(
          `User of unsupported kind '${me.userKind}' hit the no children found page.`
        )
      );
      return (
        <div>
          <H1>Unsupported user type</H1>
          <P>
            We're sorry, but you've reached this page in error. Our engineers
            have already been notified, but you can reach out to{" "}
            <TextLink href={supportMailTo}>our support staff</TextLink> if you
            have questions.
          </P>
        </div>
      );
  }
};

export type NoChildrenFoundPageWithDataProps = { me?: User };
export const NoChildrenFoundPageWithData: React.FC<NoChildrenFoundPageWithDataProps> = ({
  me,
}) => {
  if (!me) {
    return <DashboardLayout loading />;
  }
  return (
    <DashboardLayout topNav={{ type: "titleTopNav", title: "" }}>
      <Container>
        <Content me={me} />
      </Container>
    </DashboardLayout>
  );
};

export type NoChildrenFoundPageProps = {};

const NoChildrenFoundPage: React.FC<NoChildrenFoundPageProps> = () => {
  const { data: { data: me } = {} } = useApi().useGetMe();
  return <NoChildrenFoundPageWithData me={me} />;
};

export default NoChildrenFoundPage;
