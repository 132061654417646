import { useFormik } from "formik";
import TealButton from "../../components/buttons/TealButton/TealButton";
import CheckboxField from "../../components/Forms/CheckboxField/CheckboxField";
import DateField from "../../components/Forms/DateField/DateField";
import SelectField from "../../components/Forms/SelectField/SelectField";
import TextField from "../../components/Forms/TextField/TextField";
import TextLink from "../../components/TextLink/TextLink";
import { careCoordinatorEmail, languageOptions } from "../../constants";
import CombinedLegalText from "./CombinedLegalText";
import {
  Container,
  Description,
  Errors,
  ErrorSection,
  FormGroup,
  FormRowGrid,
  H1,
  H2,
  H3,
  HeaderSection,
  InsuranceButtonSection,
  InsuranceSection,
  LanguageSwitchButton,
  LegalSection,
  LegalText,
  Wrap,
  ParentInfoSection,
  PatientInfoSection,
  ScheduleButtonSection,
  ScheduleSection,
  SignatureCheckboxes,
  SignatureDescription,
  SignatureTextbox,
  SubmitButtonSectionV2,
  PleaseConsent,
  ButtonGroup,
  LanguageButton,
} from "./elements";
import { PreferredLanguage } from "../../api/queries/partialReferrals";
import { mockParentWelcomePacket } from "../../mocks/parentWelcomePackets";
import PWPInsuranceModal from "../../components/Modals/PWPInsuranceModal/PWPInsuranceModal";
import PWPSchedulingModal from "../../components/Modals/PWPSchedulingModal/PWPSchedulingModal";
import { Trans } from "react-i18next";
import {
  isDaybreakError,
  isDaybreakErrorV4,
  RequestError,
} from "../../api/apiClient";
import {
  GuardianRelationship,
  guardianRelationships,
  ParentWelcomePacket,
  PostParentWelcomePacketParameters,
} from "../../api/queries/parentWelcomePackets";
import React, {
  useEffect,
  useState,
  useMemo,
  Dispatch,
  SetStateAction,
} from "react";
import ParentWelcomePacketLayout from "../../layouts/ParentWelcomePacketLayout/ParentWelcomePacketLayout";
import { datadogRum } from "../../client/datadogHelper";
import pwpSchema from "./ParentWelcomePacketFormSchema";
import { get, startCase } from "lodash";
import { useModal } from "../../components/Modals/BaseModal/BaseModal";
import en from "./ParentWelcomePacket.en.json";
import es from "./ParentWelcomePacket.es.json";
import { useDaybreakTranslation } from "../../hooks/useDaybreakTranslation";
import { trackEvent } from "client/amplitudeHelper";

const getSaveError = (postPWPError: RequestError | undefined) => {
  if (!postPWPError) {
    return undefined;
  }
  if (isDaybreakError(postPWPError)) {
    return postPWPError.error;
  } else if (isDaybreakErrorV4(postPWPError)) {
    if (postPWPError.errorType === "data_not_acceptable") {
      let errorString = "";
      for (const key in postPWPError.errorData) {
        errorString += `${startCase(key)} ${postPWPError.errorData[key]}.`;
      }
      return errorString;
    } else {
      return postPWPError.error;
    }
  }
  return "unknown error";
};
const getErrorDisplayStrings = (
  saveError: string | undefined,
  insuranceError: boolean,
  scheduleError: boolean,
  validationErrors: any[]
) => {
  let errors = validationErrors ?? [];

  if (insuranceError) {
    errors.push("You must submit insurance.");
  }
  if (scheduleError) {
    errors.push("You must schedule an intake appointment.");
  }
  if (saveError) {
    errors.push(saveError);
  }
  return errors;
};
export const addLanuageToSfUrl = (rawUrl: string, language: string) => {
  let sfLanguage = "";

  // The only supported SF languages right now are English and Spanish.
  if (language.startsWith("en")) {
    sfLanguage = "en_us";
  } else if (language.startsWith("es")) {
    sfLanguage = "es";
  }

  try {
    const url = new URL(rawUrl);
    const params = new URLSearchParams(url.search);
    params.set("language", sfLanguage);
    url.search = params.toString();
    return url.toString();
  } catch (error) {
    // Handle parsing errors if a bad url was passed in.  Usual cause: you gave
    // `new URL()` a relative URL like `/foo/bar` instead of an absolute one
    // like `http://example.com/foo/bar`.
    if (
      error instanceof TypeError &&
      error.message === "Failed to construct 'URL': Invalid URL"
    ) {
      console.error("Failed to parse url:", rawUrl);
      datadogRum.addError(error);

      // At least return the url with the default language.
      return rawUrl;
    } else {
      // Don't swallow other errors.
      throw error;
    }
  }
};

const checkActiveElement = (
  setActiveField: Dispatch<SetStateAction<string>>
) => {
  setTimeout(() => {
    const inForm = document.activeElement?.closest("form.pwpForm");
    if (!inForm) {
      setActiveField("");
    } else {
      if (document.activeElement instanceof HTMLSelectElement) {
        setActiveField(document.activeElement?.name);
      } else if (document.activeElement instanceof HTMLInputElement) {
        setActiveField(document.activeElement?.id);
      }
    }
  }, 0);
};

export type ParentWelcomePacketFormWithDataProps = {
  organizationApiKey: string;
  parentWelcomePacket?: ParentWelcomePacket;
  postParentWelcomePacketError?: RequestError;
  isSavingPWP: boolean;
  maybeSavePWP: (
    activeFieldValue: string,
    values: PostParentWelcomePacketParameters[0],
    current: ParentWelcomePacket | undefined,
    options?: PostParentWelcomePacketParameters[1],
    force?: boolean
  ) => void;
  consentBlocking: boolean;
};
export const ParentWelcomePacketFormWithData: React.FC<ParentWelcomePacketFormWithDataProps> = ({
  organizationApiKey,
  parentWelcomePacket,
  maybeSavePWP,
  postParentWelcomePacketError,
  isSavingPWP,
  consentBlocking,
}) => {
  const { t, i18n: tContext } = useDaybreakTranslation("pwpForm", {
    en,
    es,
  });

  const changeLanguage = (language: string) => {
    trackEvent("PWP:languageChange", {
      to: language,
    });
    tContext.changeLanguage(language);
  };

  const language = tContext.language;
  // dgsan 2023-12-11
  // When using a Test org in prod this was always
  // turning into an empty string, preventing
  // packet submission. This gives it a fallback
  // 'TBD' value so text can always be present
  // - it seems better than an error with poor
  // visibility and no user resolution.
  const districtName = parentWelcomePacket?.organizationName ?? "TBD";
  const agreementText = useMemo(() => {
    if (language && districtName !== "") {
      return t("legalText", { schoolName: districtName, careCoordinatorEmail });
    } else {
      return "";
    }
  }, [districtName, t, language]);

  // We track this separately from react-query's isLoading because we want to
  // differentiate between true submitting and just autosaving.
  const [isSigning, setIsSigning] = useState(false);
  // TBD: can we detect an initial active form element?
  const [activeField, setActiveField] = useState("");
  const [hasScheduledIntake, setHasScheduledIntake] = useState(false);
  const schedulingCompleted =
    parentWelcomePacket?.schedulingCompleted || hasScheduledIntake;
  type MaybeSavePWPParameters = Parameters<typeof maybeSavePWP>;
  const maybeSavePWPPrefilled = (
    activeFieldValue: string,
    values: Omit<
      MaybeSavePWPParameters[1],
      "orgApiKey" | "schedulingCompleted"
    >,
    current: MaybeSavePWPParameters[2],
    options?: MaybeSavePWPParameters[3],
    force?: MaybeSavePWPParameters[4]
  ) => {
    const valuesToSave = {
      ...values,
      orgApiKey: organizationApiKey,
      schedulingCompleted: schedulingCompleted,
    };
    maybeSavePWP(activeFieldValue, valuesToSave, current, options, force);
  };

  // If you're testing this out and are tired of filling the form out every
  // single time, set this localstorage value to prefill with test data.
  // window.localStorage.setItem("prefillTestValues", true)
  // window.localStorage.removeItem("prefillTestValues")
  const testDefaultValues = window.localStorage.getItem("prefillTestValues")
    ? mockParentWelcomePacket()
    : null;
  const rawFormik = useFormik({
    initialValues: parentWelcomePacket ??
      testDefaultValues ?? {
        patientFirstName: "",
        patientLastName: "",
        patientBirthDate: "",
        patientPreferredLanguage: "english" as PreferredLanguage,
        patientPreferredLanguageOther: "",
        guardianRelationship: "mother" as GuardianRelationship,
        guardianFirstName: "",
        guardianLastName: "",
        guardianPhoneNumber: "",
        guardianEmail: "",
        guardianPreferredLanguage: "english" as PreferredLanguage,
        guardianPreferredLanguageOther: "",
        agreementText: t("legalText", {
          schoolName: districtName,
          careCoordinatorEmail: careCoordinatorEmail,
        }),
        agreementTosPp: false,
        agreementIc: false,
        agreementAobHipaa: false,
        agreementHipaaRoi: false,
        agreementSignature: "",
      },
    validationSchema: pwpSchema,
    onSubmit: async (values) => {
      if (agreementText?.length < 1) {
        trackEvent("PWP:invalidAgreementText");
        return;
      }
      const valuesToSave = {
        complete: true,
        ...values,
        agreementText,
      };
      setIsSigning(true);
      trackEvent("PWP:signing:start");
      maybeSavePWPPrefilled(
        activeField,
        valuesToSave,
        parentWelcomePacket,
        {
          onSettled: () => {
            setIsSigning(false);
            trackEvent("PWP:signing:end");
          },
        },
        true
      );
    },
  });

  // In order to react to losing focus on all text fields, we wrap formik so
  // that *this* handleBlur function gets called by all form fields.
  const formik = {
    ...rawFormik,
    handleBlur: (arg: unknown) => {
      // When a field loses focus, save the form.
      maybeSavePWPPrefilled(activeField, formik.values, parentWelcomePacket);

      // But also call formik's real handleBlur function.
      rawFormik.handleBlur(arg);
    },
  };

  const validationErrors = Object.values(formik.errors);
  const insuranceError =
    parentWelcomePacket?.insuranceRequired &&
    !parentWelcomePacket.insuranceCompleted;
  const scheduleError =
    parentWelcomePacket?.schedulingRequired &&
    !parentWelcomePacket.schedulingCompleted;
  const saveError = getSaveError(postParentWelcomePacketError);
  const errorDisplayStrings = getErrorDisplayStrings(
    saveError,
    !!insuranceError,
    !!scheduleError,
    validationErrors
  );

  const useCollectInsuranceModal = useModal({
    onOpen: () => trackEvent("PWP:openedInsuranceModal"),
    onClose: () => {
      // Reload the form to find out if the insurance is now completed.  Can't
      // do a GET because that's too heavily cached on the server.
      trackEvent("PWP:closedInsuranceModal");
      maybeSavePWPPrefilled(
        activeField,
        formik.values,
        parentWelcomePacket,
        {},
        true
      );
    },
  });

  const usePwpSchedulingModal = useModal({
    onOpen: () => trackEvent("PWP:openedSchedulingModal"),
    onClose: () => trackEvent("PWP:closedSchedulingModal"),
  });

  useEffect(() => {
    if (parentWelcomePacket?.schedulingRequired) {
      trackEvent("PWP:showSchedulingButton");
    }
  }, [parentWelcomePacket?.schedulingRequired]);

  // Save form every few seconds if changed
  useEffect(() => {
    const interval = setInterval(() => {
      maybeSavePWP(
        activeField,
        {
          ...formik.values,
          schedulingCompleted,
          orgApiKey: organizationApiKey,
        },
        parentWelcomePacket,
        {},
        false
      );
    }, 2500);
    return () => clearInterval(interval);
  });

  const handleInputEvent = (e: React.FocusEvent<HTMLInputElement>) => {
    setActiveField(e.target.id);
    trackEvent(`PWP:${e.type}:${e.target.id}`, {
      value: e.target.value,
      checked: e.target.checked,
      valid: get(formik.errors, e.target.id) === "",
    });
    if (e.type === "focus") {
      maybeSavePWPPrefilled(activeField, formik.values, parentWelcomePacket);
    } else if (e.type === "blur") {
      checkActiveElement(setActiveField);
    }
  };

  const handleSelectEvent = (e: React.FocusEvent<HTMLSelectElement>) => {
    setActiveField(e.target.name);
    trackEvent(`PWP:${e.type}:${e.target.id}`, {
      value: e.target.value,
      valid: get(formik.errors, e.target.id) === "",
    });
    if (e.type === "focus") {
      maybeSavePWPPrefilled(activeField, formik.values, parentWelcomePacket);
    } else if (e.type === "blur") {
      checkActiveElement(setActiveField);
    }
  };
  const packetSigned = parentWelcomePacket?.completedDate ? true : false;
  const packetLinked = parentWelcomePacket?.linkedAndConfigured ?? false;

  const lockSigning = isSavingPWP || isSigning || !packetLinked || packetSigned;
  useEffect(() => {
    if (lockSigning) {
      trackEvent("PWP:SigningLocked", {
        lockSigning,
      });
    } else {
      trackEvent("PWP:SigningUnlocked", {
        lockSigning,
      });
    }
  }, [lockSigning]);
  return (
    <ParentWelcomePacketLayout>
      {parentWelcomePacket?.insuranceLink &&
        useCollectInsuranceModal.asModal(
          <PWPInsuranceModal
            insuranceLink={addLanuageToSfUrl(
              parentWelcomePacket.insuranceLink,
              language
            )}
          />
        )}
      {parentWelcomePacket?.schedulingLink &&
        usePwpSchedulingModal.asModal(
          <PWPSchedulingModal
            schedulingLink={parentWelcomePacket.schedulingLink}
            onSchedule={() => {
              // usePWPSchedulingModel takes an "onSchedule" function.
              trackEvent("PWP:intakeScheduled");

              // Set local state so that the schedule is immediately disabled.
              setHasScheduledIntake(true);

              // Save the form with 'schedulingCompleted' set to true.
              maybeSavePWP(
                activeField,
                {
                  ...formik.values,
                  schedulingCompleted: true,
                  orgApiKey: organizationApiKey,
                },
                parentWelcomePacket,
                {},
                true
              );
            }}
          />
        )}
      <Container>
        <form className="pwpForm" onSubmit={formik.handleSubmit}>
          <HeaderSection>
            <H1>{t("consentTitle")}</H1>
            <LanguageSwitchButton>
              <ButtonGroup>
                <LanguageButton
                  variant="secondary"
                  isActive={language === "en"}
                  onClick={() => changeLanguage("en")}
                >
                  English
                </LanguageButton>
                <LanguageButton
                  variant="secondary"
                  isActive={language === "es"}
                  onClick={() => changeLanguage("es")}
                >
                  Español
                </LanguageButton>
              </ButtonGroup>
            </LanguageSwitchButton>
            <Description>
              <Trans components={{ p: <p />, b: <b />, i: <i />, a: <a /> }}>
                {t("descriptionText")}
              </Trans>
            </Description>
          </HeaderSection>
          <PatientInfoSection>
            <H2>{t("studentInformationTitle")}</H2>
            <FormGroup>
              <FormRowGrid>
                <TextField
                  disabled={isSigning}
                  id="patientFirstName"
                  label={t("labelFirstName") ?? ""}
                  formik={formik}
                  onBlur={handleInputEvent}
                  onFocus={handleInputEvent}
                />
                <TextField
                  disabled={isSigning}
                  id="patientLastName"
                  label={t("labelLastName") ?? ""}
                  formik={formik}
                  onFocus={handleInputEvent}
                  onBlur={handleInputEvent}
                />
              </FormRowGrid>
              <FormRowGrid>
                <DateField
                  disabled={isSigning}
                  id="patientBirthDate"
                  label={t("labelBirthDate") ?? ""}
                  formik={formik}
                  onFocus={handleInputEvent}
                  onBlur={handleInputEvent}
                />
              </FormRowGrid>
              <FormRowGrid>
                <SelectField
                  disabled={isSigning}
                  label={t("labelPreferredLanguage") ?? ""}
                  name="patientPreferredLanguage"
                  formik={formik}
                  onFocus={handleSelectEvent}
                  onBlur={handleSelectEvent}
                >
                  {Object.entries(languageOptions).map(
                    ([language, displayName]) => (
                      <option key={language} value={language}>
                        {displayName}
                      </option>
                    )
                  )}
                </SelectField>
                {formik.values.patientPreferredLanguage === "other" ? (
                  <TextField
                    disabled={isSigning}
                    formik={formik}
                    name="patientPreferredLanguageOther"
                    label={t("labelSpecifyLanguage") ?? ""}
                    onFocus={handleInputEvent}
                    onBlur={handleInputEvent}
                  />
                ) : null}
              </FormRowGrid>
            </FormGroup>
          </PatientInfoSection>
          <ParentInfoSection>
            <H2>{t("parentGuardianInformationTitle")}</H2>
            <FormGroup>
              <FormRowGrid>
                <TextField
                  disabled={isSigning}
                  id="guardianFirstName"
                  label={t("labelFirstName") ?? ""}
                  formik={formik}
                  onFocus={handleInputEvent}
                  onBlur={handleInputEvent}
                />
                <TextField
                  disabled={isSigning}
                  id="guardianLastName"
                  label={t("labelLastName") ?? ""}
                  formik={formik}
                  onFocus={handleInputEvent}
                  onBlur={handleInputEvent}
                />
              </FormRowGrid>
              <FormRowGrid>
                <TextField
                  disabled={isSigning}
                  id="guardianEmail"
                  label={t("labelEmail") ?? ""}
                  formik={formik}
                  onFocus={handleInputEvent}
                  onBlur={handleInputEvent}
                />
                <TextField
                  disabled={isSigning}
                  id="guardianPhoneNumber"
                  label={t("labelPhoneNumber") ?? ""}
                  formik={formik}
                  onFocus={handleInputEvent}
                  onBlur={handleInputEvent}
                />
              </FormRowGrid>
              <FormRowGrid>
                <SelectField
                  disabled={isSigning}
                  label={t("labelRelationship") ?? ""}
                  name="guardianRelationship"
                  formik={formik}
                  onFocus={handleSelectEvent}
                  onBlur={handleSelectEvent}
                >
                  {Object.entries(guardianRelationships).map(
                    ([relationship, displayName]) => (
                      <option key={relationship} value={relationship}>
                        {displayName}
                      </option>
                    )
                  )}
                </SelectField>
              </FormRowGrid>
              <FormRowGrid>
                <SelectField
                  disabled={isSigning}
                  label={t("labelPreferredLanguage") ?? ""}
                  name="guardianPreferredLanguage"
                  formik={formik}
                  onFocus={handleSelectEvent}
                  onBlur={handleSelectEvent}
                >
                  {Object.entries(languageOptions).map(
                    ([language, displayName]) => (
                      <option key={language} value={language}>
                        {displayName}
                      </option>
                    )
                  )}
                </SelectField>
                {formik.values.guardianPreferredLanguage === "other" ? (
                  <TextField
                    disabled={isSigning}
                    formik={formik}
                    name="guardianPreferredLanguageOther"
                    label={t("labelSpecifyLanguage") ?? ""}
                    onFocus={handleInputEvent}
                    onBlur={handleInputEvent}
                  />
                ) : null}
              </FormRowGrid>
            </FormGroup>
          </ParentInfoSection>
          <LegalSection>
            <H2>{t("authorizationPrivacyTitle")}</H2>
            <LegalText
              onScroll={(e) => {
                if (
                  e.currentTarget.scrollTop + e.currentTarget.clientHeight ===
                  e.currentTarget.scrollHeight
                ) {
                  trackEvent("PWP:scrollInformedConsent");
                }
              }}
            >
              <CombinedLegalText schoolName={districtName} />
            </LegalText>
            <SignatureCheckboxes>
              <CheckboxField
                id="agreementTosPp"
                disabled={lockSigning}
                label={
                  <Trans
                    components={{
                      textLink: (
                        <TextLink href="https://www.daybreakhealth.com/daybreak-privacy-policy">
                          {t("labelTOSPP")}
                        </TextLink>
                      ),
                    }}
                  >
                    {t("labelAgreeTOSPP", {})}
                  </Trans>
                }
                formik={formik}
                onChange={handleInputEvent}
              />
              <CheckboxField
                id="agreementIc"
                disabled={lockSigning}
                label={t("labelAgreeInformedConsent") ?? ""}
                formik={formik}
                onChange={handleInputEvent}
              />
              <CheckboxField
                id="agreementAobHipaa"
                disabled={lockSigning}
                label={t("labelAgreeAOBPayers") ?? ""}
                formik={formik}
                onChange={handleInputEvent}
              />
              <CheckboxField
                id="agreementHipaaRoi"
                disabled={lockSigning}
                label={t("labelAgreeHIPAASchools") ?? ""}
                formik={formik}
                onChange={handleInputEvent}
              />
            </SignatureCheckboxes>
            <p>{t("textForMultiPartyConsent")}</p>
            <SignatureDescription>
              {t("signatureFromParentDescription")}
            </SignatureDescription>
            <SignatureTextbox>
              <TextField
                id="agreementSignature"
                disabled={lockSigning}
                label={t("labelEnterFullName") ?? ""}
                formik={formik}
                onFocus={handleInputEvent}
                onBlur={handleInputEvent}
              />
              {packetSigned && (
                <div>
                  <em>{t("signed")}</em>
                </div>
              )}
            </SignatureTextbox>
            <SubmitButtonSectionV2>
              {!packetSigned && (
                <TealButton
                  type="submit"
                  disabled={lockSigning}
                  icon={isSavingPWP || isSigning ? "clock2" : undefined}
                >
                  {t(isSigning ? "signButtonSubmitting" : "signButton")}
                </TealButton>
              )}
            </SubmitButtonSectionV2>
          </LegalSection>
          {parentWelcomePacket?.insuranceRequired &&
          !parentWelcomePacket.insuranceCompleted &&
          parentWelcomePacket.insuranceLink ? (
            <>
              <HeaderSection>
                <H1>{t("submitInsuranceTitle")}</H1>
                <Trans>{t("insuranceProgramCost")}</Trans>
              </HeaderSection>
              <InsuranceSection>
                <H3>{t("whyInsuranceRequiredTitle")}</H3>
                <Trans components={{ p: <p /> }}>
                  {t("insuranceWhyRequiredDescription")}
                </Trans>
                <H3>{t("noInsuranceTitle")}</H3>
                <Wrap>
                  <Trans
                    components={{
                      textlink: (
                        <TextLink href="https://www.daybreakhealth.com/faq" />
                      ),
                    }}
                  >
                    {t("noInsuranceDescription")}
                  </Trans>
                </Wrap>
                <InsuranceButtonSection>
                  <TealButton
                    disabled={isSavingPWP || isSigning || !packetLinked}
                    onClick={() => useCollectInsuranceModal.openModal()}
                  >
                    {t("clickToSubmitInsuranceButton")}
                  </TealButton>
                </InsuranceButtonSection>
              </InsuranceSection>
            </>
          ) : null}
          {parentWelcomePacket?.schedulingRequired &&
          parentWelcomePacket.schedulingLink ? (
            <>
              <HeaderSection>
                <H1>{t("scheduleIntakeTitle")}</H1>
                <Description>{t("selectAppointmentDescription")}</Description>
              </HeaderSection>
              <ScheduleSection>
                <ScheduleButtonSection>
                  <TealButton
                    disabled={
                      schedulingCompleted ||
                      isSavingPWP ||
                      isSigning ||
                      !packetLinked ||
                      (consentBlocking && !packetSigned)
                    }
                    onClick={() => usePwpSchedulingModal.openModal()}
                  >
                    {t(
                      schedulingCompleted
                        ? "clickToScheduleButtonDisabled"
                        : "clickToScheduleButton"
                    )}
                  </TealButton>
                </ScheduleButtonSection>
                {consentBlocking && !packetSigned ? (
                  <PleaseConsent>{t("pleaseConsent")}</PleaseConsent>
                ) : null}
              </ScheduleSection>
            </>
          ) : null}
          {/* TODO: better error UI here */}
          {errorDisplayStrings.length > 0 ? (
            <ErrorSection>
              <Errors>Error: {errorDisplayStrings.join(" ")}</Errors>
            </ErrorSection>
          ) : null}
        </form>
      </Container>
    </ParentWelcomePacketLayout>
  );
};
