import { datadogRum } from "@datadog/browser-rum";

const initDatadog = () => {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATA_DOG_APPLICATION_ID || "",
    clientToken: process.env.REACT_APP_DATA_DOG_CLIENT_TOKEN || "",
    service: process.env.REACT_APP_DATA_DOG_SERVICE,
    env: process.env.REACT_APP_DATA_DOG_ENV,
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: "datadoghq.com",
    version: "2025.01.27",
    defaultPrivacyLevel: "mask-user-input",
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    enablePrivacyForActionName: true,
  });
};

export default initDatadog;
export { datadogRum };
