import { AxiosRequestConfig } from "axios";
import {
  QueryKey,
  UseQueryOptions,
  useQuery,
  UseMutationOptions,
  useMutation,
} from "react-query";
import { RequestError, useApiClient } from "../apiClient";

/**
 * Some of our endpoints return objects that look like this:
 * ```
 * {
 *   id: 123,
 *   type: "user",
 *   attributes: { name: 'kevin', age: 100 },
 *   relationships: {
 *     counselor: {
 *       data: {id: 123}
 *     }
 *   }
 * }
 * ```
 * This function takes that object and merges the attributes into the root object:
 * ```
 * {
 *   id: 123,
 *   type: "user",
 *   name: "kevin",
 *   age: 100,
 *   counselor: {
 *     id: 123
 *   }
 * }
 * ```
 *
 * If you provide an optional modelMap like:
 * ```
 * {
 *   counselor: {
 *     123: {
 *       name: "Sam",
 *       age: 65,
 *       id: 123
 *     },
 *     456: {
 *       name: "Jane",
 *       age: 65,
 *       id: 123
 *     }
 *   }
 * }
 * ```
 * Then the data in there will be merged into the appropriate relationship
 * object in the output:
 * ```
 * {
 *   id: 123,
 *   type: "user",
 *   name: "kevin",
 *   age: 100,
 *   counselor: {
 *     id: 123,
 *     name: "Sam",
 *     age: 65
 *   }
 * }
 * ```
 */
type ModelMap = Record<string, Record<string, any>>;
export const convertSerializedType = (
  rawResponseData: Record<any, any> & {
    attributes: Record<any, any>;
  },
  modelMap: ModelMap = {}
) => {
  if (rawResponseData == null) return rawResponseData;

  const { attributes, relationships, ...otherFields } = rawResponseData;
  const flattenedRelationships: typeof relationships = {};
  for (const key in relationships) {
    const relationship = relationships[key];
    // Relationships may have their attributes nested under a "data" key.  Let's
    // flatten that.
    flattenedRelationships[key] = Array.isArray(relationship.data)
      ? relationship.data
      : { ...relationship.data };

    // If the relationship is in a modelMap, use the data from there.
    if (Array.isArray(flattenedRelationships[key])) {
      flattenedRelationships[key].forEach((element: any, dx: number) => {
        if (modelMap?.[element.type]?.[element.id]) {
          flattenedRelationships[key][dx] = convertSerializedType(
            modelMap[element.type][element.id],
            modelMap
          );
        }
      });
    } else if (!relationship.data) {
      flattenedRelationships[key] = null;
    } else if (modelMap[relationship.data.type]) {
      flattenedRelationships[key] = convertSerializedType(
        modelMap[relationship.data.type][relationship.data.id],
        modelMap
      );
    }
  }

  return {
    ...otherFields,
    ...attributes,
    ...flattenedRelationships,
  };
};

/**
 * Do a bit of preprocessing on errors our backend intentionally returns so we
 * can more easily access the request body.  Usage:
 *
 * // In the query:
 * withApiErrorHandling(async () =>{
 *   return await apiClient.get('/v1/whatever);
 * })
 *
 * // In your component that uses this query:
 * const { isLoading, error } = useApi().useSomeQuery();
 * if (isDaybreakError(error)) {
 *   error_type = error.error_type;
 * } else {
 *   .notify(`Unknown error on invite page: ${error}`);
 * }
 */
export const withApiErrorHandling = async (
  requestFunction: () => Promise<any>
) => {
  try {
    return await requestFunction();
    // TODO: remove `any` here when TS supports optional chaining on type
    // "unknown": https://github.com/microsoft/TypeScript/issues/37700
  } catch (error: any) {
    if (error?.response?.data) {
      throw error?.response?.data;
    }
    throw error;
  }
};

type MutationRequestBuilder<VariablesType> = (
  requestBuilderArguments: VariablesType
) => AxiosRequestConfig;

type MutationHookArguments<ResponseDataType, VariablesType, MetaType> = {
  options?: UseMutationOptions<
    { data: ResponseDataType; meta: MetaType },
    RequestError,
    VariablesType
  >;
  auth?: boolean;
};

/**
 * createUseApiMutation creates a custom hook for mutations
 * (post/put/patch/delete requests) to our api.
 *
 * You'd use it something like:
 *
 * ```
 *  export const usePostComment = createUseApiMutation<
 *    Comment,
 *    {body: string, title: string, postId: number}
 *  >({
 *    requestBuilder: ({body, title, postId}) => ({
 *      method: "post",
 *      url: `/v1/posts/${postId}/comments`,
 *      data: {
 *        body: body,
 *        title: title.trim()
 *      },
 *    }),
 *  });
 * ```
 * ... which produces a hook that you can use in your components like:
 *
 * ```
 * const { mutate: createComment } = usePostComment();
 * // And then later, in a click handler or something:
 * mutate({body: "foo", title: "bar", postId: 123});
 * ```
 *
 * @param mutationBuilderOptions A hash with the following keys:
 * - requestBuilder (required): A function which returns an
 *   AxiosRequestConfig object to be used for the request.  Usually you want to
 *   set the "method", "url", and "data" keys here, but you can set any option
 *   that Axios supports.
 * - defaultMutationOptions (optional): a set of mutation options to be passed
 *   directly to ReactQuery's useMutation.
 * @returns
 */
export const createUseApiMutation = <
  /**
   * The type of the data you expect the server to return in its JSON under the
   * `data` key.
   */
  ResponseDataType,
  /**
   * The type of the variables you'll pass to the mutation function - the one
   * that actually triggers the request.
   *
   * Eg if you have a hook like:
   *
   *    const { mutate: makeUser } = usePostUser(...)
   *    ...
   *    makeUser(name: 'foo', age: 123)
   *
   * Then the VariablesType would be:
   *
   *    {name: string, age: number}
   *
   */
  VariablesType,
  /**
   * The type of the data you expect the server to return in its JSON under the
   * `meta` key.
   */
  MetaType = PageMetaTypeV1
>(mutationBuilderOptions: {
  requestBuilder: MutationRequestBuilder<VariablesType>;
  defaultMutationOptions?: UseMutationOptions<
    { data: ResponseDataType; meta: MetaType },
    RequestError,
    VariablesType
  >;
  auth?: boolean;
}) => {
  return (
    hookArguments: MutationHookArguments<
      ResponseDataType,
      VariablesType,
      MetaType
    >
  ) => {
    // Get an Axios isntance
    const apiClient = useApiClient({
      auth: mutationBuilderOptions.auth ?? hookArguments.auth ?? true,
    });

    // Create the actual useMutation hook from the ReactQuery library
    return useMutation<
      { data: ResponseDataType; meta: MetaType },
      RequestError,
      VariablesType
    >(
      async (requestArguments: VariablesType) => {
        // This is the bit that runs when you actually call the mutation function
        // (`mutate()`), not just when you call the mutation hook (`const {mutate}
        // = usePostWhatever()`)

        const config = mutationBuilderOptions.requestBuilder(requestArguments);
        return withApiErrorHandling(async () => {
          const response = await apiClient(config);
          return handleResponseData(response.data);
        });
      },
      // TODO: Currently, if you have an onSuccess handler in both the
      // defaultQueryOptions and the hookArguments, the latter overwrites the
      // first.  Consider merging the two in that case, and updating
      // createUseApiQuery the same way.

      // ReactQuery has a lot of useful options to customize its behavior on a
      // per-request basis.  You can pass in an `options` hash to the created
      // hook and it'll be passed directly on to ReactQuery.  You can also pass
      // a `defaultMutationOptions` hash when you create the hook.
      {
        ...(mutationBuilderOptions.defaultMutationOptions ?? {}),
        ...(hookArguments?.options ?? {}),
      }
    );
  };
};

export type PageMetaType = {
  pagination: {
    page: number;
    lastPage: number;
    perPage: number;
    totalRecords: number;
  };
};
export type PageMetaTypeV1 = {
  currPage: number;
  maxPage: number;
  totalRecords: number;
};
type HookArguments<ResponseDataType, ArgumentsType> = ArgumentsType & {
  options?: UseQueryOptions<ResponseDataType, RequestError>;
  auth?: boolean;
};
type QueryBuilder<ResponseDataType, ArgumentsType> = (
  hookArguments: HookArguments<ResponseDataType, ArgumentsType>
) => { cacheKey: QueryKey; url: string; config?: AxiosRequestConfig };
/**
 * createUseApiQuery creates a custom hook for querying our api.  You'd usually
 * use it something like:
 *
 * ```
 * export const useGetComments = createUseApiQuery<Comments[], {}>({
 *   queryBuilder: ({ view = "parent" } = {}) => {
 *     return { cacheKey: ["comments"], url: `/v1/comments` };
 *   },
 * });
 * ```
 *
 * ...which will produce a hook that you can use in your components
 * like:
 *
 * const { data: comments, isLoading } = useGetComments();
 *
 * @param queryBuilderOptions A hash with the following keys:
 *  - queryBuilder(required): A function that returns a hash containing the
 *  url to request, the ReactQuery queryKey to use for caching, and the Axios
 *  config to use for the request (which allows for setting params and headers).
 *  - defaultQueryOptions (optional): a set of query options to be passed
 *  directly to ReactQuery's useQuery.
 *  - auth (optional): a boolean indicating that this query should or should not
 *  always use authentication.
 * @returns a custom hook that takes in as arguments whatever type you specified
 * in the ArgumentsType generic.  It also takes in an `options` hash to be
 * passed to ReactQuery (and will be merged into the defaultQueryOptions).  The
 * hook's return value will be the same return value as ReactQuery's useQuery
 * hook.
 *
 * Example:
 * ```
 * const useGetUser = createUseApiQuery<User, {id: string}>(...);
 * const {data, isLoading} = useGetUser({id: 123, options: {retry: false}});
 * ```
 */
export const createUseApiQuery = <
  ResponseDataType,
  ArgumentsType,
  MetaType = PageMetaType
>(queryBuilderOptions: {
  queryBuilder: QueryBuilder<
    { data: ResponseDataType; meta: MetaType; included?: any },
    ArgumentsType
  >;
  defaultQueryOptions?: UseQueryOptions<
    { data: ResponseDataType; meta: MetaType; included?: any },
    RequestError
  >;
  auth?: boolean;
}) => {
  return (
    hookArguments: HookArguments<
      { data: ResponseDataType; meta: MetaType },
      ArgumentsType
    >
  ) => {
    // Grab the configurable specifics of this api call: url, params, etc.
    const { cacheKey, url, config } = queryBuilderOptions.queryBuilder(
      hookArguments
    );

    // Get an Axios isntance
    const apiClient = useApiClient({
      auth: queryBuilderOptions.auth ?? hookArguments.auth ?? true,
    });

    // Call ReactQuery's useQuery function, which provides caching, retries, and
    // other niceties.
    return useQuery<
      { data: ResponseDataType; meta: MetaType; included?: any },
      RequestError
    >(
      // Query key, as defined by React Query.
      cacheKey,

      // A function to actually make the request, getting passed to ReactQuery
      async () => {
        // Wrap the request with handling for Daybreak's specific api errors
        return withApiErrorHandling(async () => {
          const response = await apiClient.get(url, config);
          return handleResponseData(response.data);
        });
      },

      // ReactQuery has a lot of useful options to customize its behavior on a
      // per-request basis.  You can pass in an `options` hash to the created
      // hook and it'll be passed directly on to ReactQuery.  You can also pass
      // a `defaultQueryOptions` hash when you create the hook.
      {
        ...(queryBuilderOptions.defaultQueryOptions ?? {}),
        ...(hookArguments?.options ?? {}),
      }
    );
  };
};

type ResponseDataType = {
  data: any;
  meta: any;
  included?: any;
};
export const handleResponseData = (responseBody: any): ResponseDataType => {
  if (!responseBody) {
    return { data: undefined, meta: undefined };
  }
  const returnedArray = Array.isArray(responseBody.data);
  // Convert the array of included records under the "included" key into a mapping:
  // record type -> record id -> record
  // Eg:
  // {
  //   birds: {1: {...a bird record...}, 2: {...a bird record...}}
  //   cats: {1: {...a cat record...}, 2: {...a cat record...}}
  // }
  const modelMap: Record<string, any> = {};
  (responseBody.included ?? []).forEach((record: any) => {
    modelMap[record.type] = modelMap[record.type] ?? {};
    modelMap[record.type][record.id] = record;
  });

  const responseData = returnedArray
    ? responseBody.data.map((record: any) =>
        convertSerializedType(record, modelMap)
      )
    : convertSerializedType(responseBody.data, modelMap);
  const apiData: ResponseDataType = {
    data: responseData,
    meta: responseBody.meta,
  };
  if (responseBody.included) {
    apiData.included = responseBody.included;
  }
  return apiData;
};

export const optionalParams = (params: Record<string, unknown>) => {
  const resultParams = { ...params };
  Object.keys(params).map(
    (key) => resultParams[key] === undefined && delete resultParams[key]
  );
  return resultParams;
};

export type V4PaginationParams = { page: number; perPage: number };
