// This import *must* come first so that any errors in subsequent imports get reported.
import { datadogRum } from "../client/datadogHelper";
import { ErrorBoundary } from "react-error-boundary";

import React from "react";
import App from "./App";
import RootErrorDisplay from "../components/RootErrorDisplay/RootErrorDisplay";

const AppWithErrorHandling: React.FC = () => {
  return (
    <ErrorBoundary
      fallback={RootErrorDisplay}
      onError={(error, errorInfo) => {
        datadogRum.addError(error, { errorInfo });
      }}
    >
      <App />
    </ErrorBoundary>
  );
};

export default AppWithErrorHandling;
