import React from "react";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { ThemeProvider } from "styled-components/macro";
import { ApiProvider } from "../api/apiContext";
import DaybreakAuthProvider from "../api/DaybreakAuthProvider";
import queryClient from "../api/queryClient";
import useIntercom from "../hooks/useIntercom";
import DefaultStyles from "./DefaultStyles";
import Routes from "./Routes/Routes";
import SendBirdStore from "./SendBirdStore";
import { theme } from "./theme";
import UIStateStore from "./UIStateStore";
import { Toaster } from "react-hot-toast";
import initI18N from "./i18n";
import { initializeAmplitude } from "client/amplitudeHelper";

// initialize amplitude
initializeAmplitude();

// Initialize our i18n library
initI18N();

function App() {
  useIntercom();
  return (
    <div className="App">
      <DaybreakAuthProvider skipRedirectCallback={true}>
        <ApiProvider>
          <ThemeProvider theme={theme}>
            <DefaultStyles />
            <QueryClientProvider client={queryClient}>
              <SendBirdStore>
                <UIStateStore>
                  <Toaster />

                  <Routes />
                </UIStateStore>
                {/* React query dev tools are automatically excluded in non-dev contexts */}
                <ReactQueryDevtools initialIsOpen={false} />
              </SendBirdStore>
            </QueryClientProvider>
          </ThemeProvider>
        </ApiProvider>
      </DaybreakAuthProvider>
    </div>
  );
}

// The app component is our top-level component (rendered by index.tsx). It
// handles orchestration of some assorted high-level concerns (logging, api
// access, theming, etc).
export default App;
