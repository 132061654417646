import { DateTime } from "luxon";
import React, { useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components/macro";
import { useApi } from "../../../api/apiContext";
import { OrganizationMember } from "../../../api/queries/organizationMembers";
import { Organization } from "../../../api/queries/organizations";
import { OrganizationStaffMember } from "../../../api/queries/organizationStaffMembers";
import { User } from "../../../api/queries/users";
import { datadogRum } from "../../../client/datadogHelper";
import AcuityScheduler, {
  AcuityConversionMessageData,
} from "../../../components/AcuityScheduler/AcuityScheduler";
import analytics from "../../../lib/analytics";
import { useQueryParams } from "../../../hooks/useQueryParams";
import SchoolCounselorLayout from "../../../layouts/SchoolCounselorLayout/SchoolCounselorLayout";
import { PartialReferral } from "../../../api/queries/partialReferrals";
import { isDaybreakErrorV4 } from "../../../api/apiClient";
import InformativeError from "../../../components/InformativeError/InformativeError";
import toast from "react-hot-toast";
import { trackEvent } from "client/amplitudeHelper";

const Container = styled.div``;
const SchedulerContainer = styled.div`
  // TODO: subtract the height of whatever header we have here.
  height: calc(100vh - 100px);
`;
export type SchoolSchedulingPageWithDataProps = {
  organizationMember: OrganizationMember;
  appointmentType: string;
  me: User;
  onSchedule: (scheduleData: AcuityConversionMessageData) => void;
  referralExternalId: string;
  organization: Organization;
  myStaff: OrganizationStaffMember;
  useBasicScheduling: boolean;
  partialReferral?: PartialReferral;
};
export const SchoolSchedulingPageWithData: React.FC<SchoolSchedulingPageWithDataProps> = ({
  organizationMember,
  appointmentType,
  me,
  onSchedule,
  referralExternalId,
  organization,
  myStaff,
  useBasicScheduling,
  partialReferral,
}) => {
  const localDateString = organizationMember.dateOfBirth;
  const formattedDOB = useMemo(() => {
    if (localDateString) {
      const lux = DateTime.fromISO(localDateString);
      if (!lux.isValid) {
        return;
      }
      return lux
        .setLocale("en-US")
        .toLocaleString({ day: "2-digit", month: "2-digit", year: "numeric" });
    } else {
      return;
    }
  }, [localDateString]);
  return (
    <SchoolCounselorLayout>
      <Container>
        <SchedulerContainer>
          <AcuityScheduler
            appointmentType={appointmentType}
            onSchedule={onSchedule}
            prefill={{
              firstName: organizationMember.firstName,
              lastName: organizationMember.lastName,
              email: organizationMember.studentEmail,
              studentUniqueId: organizationMember.uniqueStudentId,
              phone: partialReferral?.patientPhoneNumber,
              /*
              // Per Nikta 2022-10-27
              // Do not fill "student personal email address"
              // with the same email as the student email
              // address.
              */
              // studentEmail: organizationMember.studentEmail,
              studentDOB: formattedDOB,
              referringStaffFirstAndLastName: me.fullName,
              schoolDistrictName: organization.name,
              referringStaffTitle: myStaff.title,
              referringStaffEmail: myStaff.email,
              referringStaffFirstName: myStaff.firstName,
              referringStaffLastName: myStaff.lastName,
              referralExternalId: referralExternalId,
              organizationId: organization.id,
              organizationMemberId: organizationMember.id,
              organizationStaffMemberId: myStaff.id,
            }}
            isBasic={useBasicScheduling}
          />
        </SchedulerContainer>
      </Container>
    </SchoolCounselorLayout>
  );
};

// Welcome to Acuity, the scheduling app where everything's made up and the
// datetime formats don't matter.  For some reason we've seen several different
// datetime formats in live use, so we have to check multiple options.
const formatStrings = [
  // December 4, 2022 2:30pm
  "LLLL d, yyyy h:mma",

  // December 4, 2022 14:30
  "LLLL d, yyyy H:mm",
];
export const parseAcuityDateTime = ({
  date,
  time,
}: {
  date: string;
  time: string;
}) => {
  const timeString = date + " " + time;

  let dateTime = DateTime.invalid("no format strings");

  for (let i = 0; i < formatStrings.length; i++) {
    dateTime = DateTime.fromFormat(timeString, formatStrings[i]);
    if (dateTime.isValid) return dateTime;
  }

  return dateTime;
};

export type SchoolSchedulingPageProps = {};
const SchoolSchedulingPage: React.FC<SchoolSchedulingPageProps> = () => {
  const history = useHistory();

  const { organizationMemberId } = useParams<{
    organizationMemberId?: string;
  }>();

  const queryParams = useQueryParams();
  const partialReferralId = queryParams.get("partialReferralId") ?? undefined;

  const api = useApi();

  const {
    data: { data: organizationMember } = {},
    isLoading: loadingOrganizationMember,
    error,
  } = api.useGetOrganizationMemberV4({
    externalId: organizationMemberId ?? "",
    options: { enabled: !!organizationMemberId },
  });

  analytics.usePage("SCD:SchoolSchedulingPage", {
    organizationMemberId,
  });

  if (!organizationMemberId) {
    throw Error("Missing organizationMemberId in path");
  }

  // Get our current organization
  const {
    data: { data: organization } = {},
    isLoading: loadingOrganization,
  } = api.useGetOrganization({
    apiKey: "me",
  });

  const {
    data: { data: myStaff } = {},
    isLoading: loadingMyStaff,
  } = api.useGetMyStaff();

  const { data: { data: me } = {}, isLoading: loadingMe } = api.useGetMe();

  const {
    mutateAsync: createIntakeViaReferral,
  } = api.usePostIntakesViaReferral({
    useErrorBoundary: true,
  });

  const hasPartialReferralId = !!(
    partialReferralId && partialReferralId !== ""
  );

  const {
    data: { data: partialReferral } = {},
    isLoading: loadingPartialReferral,
  } = api.useGetPartialReferral({
    id: partialReferralId ?? "",
    options: { enabled: hasPartialReferralId },
  });
  const referralId = organizationMember?.latestReferral?.id;
  const referralExternalId = organizationMember?.latestReferral?.externalId;

  if (error && isDaybreakErrorV4(error)) {
    return (
      <SchoolCounselorLayout>
        <InformativeError errorKey="getOrganizationMemberError" error={error} />
      </SchoolCounselorLayout>
    );
  } else if (
    loadingOrganizationMember ||
    loadingOrganization ||
    loadingMyStaff ||
    loadingMe ||
    (hasPartialReferralId && loadingPartialReferral)
  ) {
    return <SchoolCounselorLayout loading />;
  } else if (!referralId) {
    trackEvent("SSD:SchoolSchedulingPage:NoReferralId", {
      organizationMember,
      organization,
      myStaff,
      me,
      partialReferral,
      hasPartialReferralId,
    });
    const noReferralError = {
      error: "No referral retrieved for scheduling.",
      errorType: "noReferralError",
    };
    return (
      <SchoolCounselorLayout>
        <InformativeError
          errorKey="getNoReferralError"
          error={noReferralError}
        />
      </SchoolCounselorLayout>
    );
  } else if (!organization || !organizationMember || !me || !myStaff) {
    trackEvent("SSD:SchoolSchedulingPage:MissingExpectedResponseData", {
      organizationMember,
      organization,
      myStaff,
      me,
      partialReferral,
      hasPartialReferralId,
    });
    return (
      <SchoolCounselorLayout>
        <InformativeError
          errorKey="getMissingDataError"
          error={{ error: "Data is missing!", errorType: "missingDataError" }}
        />
      </SchoolCounselorLayout>
    );
  } else {
    const onSchedule = (scheduleData: AcuityConversionMessageData) => {
      if (!referralId) {
        trackEvent("SSD:OnSchedule:NoReferralId", {
          organizationMember,
          organization,
          myStaff,
          me,
          partialReferral,
          hasPartialReferralId,
        });
        return;
      }
      const dateTime = parseAcuityDateTime({
        date: scheduleData.ClientDate,
        time: scheduleData.ClientTime,
      });
      if (!dateTime.isValid) {
        datadogRum.addError(
          new Error(
            `Could not parse datetime string from acuity: '${scheduleData.ClientTime}', '${scheduleData.ClientDate}'`
          ),
          {
            scheduleData: {
              date: scheduleData.ClientDate,
              time: scheduleData.ClientTime,
            },
          }
        );
      }
      createIntakeViaReferral({
        referralId,
        intake: {
          startTime: dateTime.toSeconds(),
          acuityAppointmentId: scheduleData.ID,
          status: "scheduled",
          timezone: dateTime.zoneName,
          partialReferralId,
        },
      }).then((value) => {
        toast.success(
          () => (
            <div>
              <p>Successfully referred {organizationMember.firstName}!</p>
            </div>
          ),
          { duration: 30000 }
        );

        history.push(`/school/students/${organizationMember.id}`);

        analytics.track("SCD:intakeCreated", {
          referralId,
          acuityAppointmentId: scheduleData.ID,
          userId: value.data?.attributes?.userId,
          intakeId: value.data?.id,
        });
      });
    };

    const orgAppointmentType =
      organization.configuration.acuity[
        hasPartialReferralId
          ? "staff_scheduled_appointment_type_id"
          : "appointment_type_id"
      ];
    return (
      <SchoolSchedulingPageWithData
        organizationMember={organizationMember}
        appointmentType={orgAppointmentType || ""}
        me={me}
        onSchedule={onSchedule}
        referralExternalId={referralExternalId || ""}
        organization={organization}
        myStaff={myStaff}
        useBasicScheduling={hasPartialReferralId}
        partialReferral={partialReferral}
      />
    );
  }
};

export default SchoolSchedulingPage;
