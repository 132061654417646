import { useAuth0 } from "@auth0/auth0-react";
import { datadogRum } from "../../../client/datadogHelper";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import styled from "styled-components/macro";
import { isDaybreakError } from "../../../api/apiClient";
import {
  AUTH0_AUDIENCE,
  AUTH0_REDIRECT_URI,
} from "../../../api/DaybreakAuthProvider";
import { LoginResponseData, usePostLoginV3 } from "../../../api/queries/login";
import { CenteredLoading } from "../../../components/Loading/Loading";
import OnboardingLayout from "../../../layouts/OnboardingLayout/OnboardingLayout";
import { trackEvent, identifyUser, getDeviceId } from "client/amplitudeHelper";

const Container = styled.div`
  padding: 40px;
  min-height: 400px;
`;

const afterAuthPath = (
  returnTo: string | undefined,
  { data: user }: LoginResponseData
): string => {
  // If we logged in by trying to access a protected page while logged out, just
  // take us to that page.
  if (returnTo) {
    return returnTo;
  }

  // If there was no user returned, then this credential was not successfully
  // attached to a user in the backend.  For now we have no default behavior for
  // this case.  It *usually* means that someone signed up without using their
  // invite link.  Show an error page.
  if (user == null) {
    return "/no-user";
  }

  // If this is a psychiatrist, route them to the psychiatrist-specific pages
  if (user.userKind === "psychiatrist") {
    return "/psychiatry/patients";
  }

  // If this is a counselor, route them to the counselor starting page
  if (user.userKind === "counselor") {
    return "/clinician/patients";
  }

  // If this is an organization staff member, route them to org staff member starting page
  if (user.userKind === "organization_staff_member") {
    return "/school";
  }

  // Parent users go to the parent dashboard
  if (user.userKind === "parent") {
    return "/dashboard";
  }

  if (user.userKind === "teen") {
    return "/teen-user-login";
  }

  // If we don't recognize this user kind, show an error.
  return "/teen-user-login";
};

export type Auth0InnerProps = {};
const Auth0Inner: React.FC<Auth0InnerProps> = () => {
  const {
    mutate: loginMutate,
    data: loginData,
    error: loginError,
  } = usePostLoginV3({
    retry: false,
  });
  const {
    loginWithRedirect,
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    getIdTokenClaims,
    handleRedirectCallback,
  } = useAuth0();
  const [stashedAppState, setStashedAppState] = useState({
    kind: "login",
    returnTo: undefined,
    inviteToken: undefined,
    _loaded: false,
  });
  useEffect(() => {
    (async () => {
      if (isLoading) {
        return;
      }

      if (!isAuthenticated || !stashedAppState._loaded) {
        // If we're not authenticated when we land here, it's probably because we
        // *just* landed here from auth0 and there's a `code` param in the url.
        // We need to trade that to auth0 for an accessToken, so let's do that.
        try {
          const { appState } = await handleRedirectCallback();
          setStashedAppState({
            kind: appState.kind || stashedAppState.kind,
            returnTo: appState.returnTo || stashedAppState.returnTo,
            inviteToken: appState.inviteToken || stashedAppState.inviteToken,
            _loaded: true,
          });
        } catch (error) {
          if (error instanceof Error && error.message === "Invalid state") {
            // This generally happens when you're on the after-auth page and
            // refresh it.  The signed 'state' that auth0 passes in via url
            // params isn't valid on a subsequent reload.  It usually only comes
            // up when you already had some *other* error that caused you to get
            // stuck on the after-auth page.
            console.error("Got auth0 invalid state error");
            window.location.href = "/";
          } else {
            throw error;
          }
        }
      } else if (stashedAppState._loaded) {
        const token = await getAccessTokenSilently({
          // There's no redirect involved in this step, but the docs require this anyway:
          // https://auth0.github.io/auth0-react/interfaces/index.gettokensilentlyoptions.html#redirect_uri
          redirect_uri: AUTH0_REDIRECT_URI,
          audience: AUTH0_AUDIENCE,
        });
        const idTokenClaims = await getIdTokenClaims({
          audience: AUTH0_AUDIENCE,
        });

        // Send the request to our rails backend to login and ensure the user exists.
        loginMutate({
          auth0IdToken: idTokenClaims.__raw,
          auth0AccessToken: token,
          inviteToken: stashedAppState.inviteToken,
        });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    loginWithRedirect,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    !isAuthenticated || !stashedAppState._loaded,
    isLoading,
    getAccessTokenSilently,
    getIdTokenClaims,
    loginMutate,
    handleRedirectCallback,
    stashedAppState.kind,
    stashedAppState.returnTo,
    stashedAppState.inviteToken,
  ]);

  if (loginError) {
    if (isDaybreakError(loginError)) {
      if (loginError.error_type === "unverified_email_address") {
        return <Redirect to={"/unverified-email-error"} />;
      } else if (loginError.error_type === "no_invite") {
        return <Redirect to={"/no-invite-error"} />;
      } else if (loginError.error_type === "school_staff_login_disabled") {
        return <Redirect to={"/staff-login-disabled-error"} />;
      }
    }
  }

  if (loginData) {
    // Hack: this is temporary (famous last words).  We need to store the
    // onboarding data.  Specifically them milestone ids, so we can mark them
    // complete later.  There's no api to fetch them later, but building one is
    // on our todo list.  For now, just stash this data on window.  If it gets
    // cleared (eg by a window refresh), we'll just redirect back to auth0 and
    // do the login call again.  Not a great experience, but not terrible for
    // now. - kmk 2020-05-10
    (window as any).onboarding = loginData.onboarding;

    const analyticsId = "credentialId-" + loginData.user?.credential_id;
    datadogRum.setUser({
      analyticsId,
    });
    const userId = loginData.user?.credential_id ? analyticsId : getDeviceId();

    identifyUser(userId, {
      identifyVersion: 2,
      analyticsType: loginData.user?.analytics_type,
      is_test: loginData.user?.is_test,
    });

    trackEvent(stashedAppState.kind === "signup" ? "signUp" : "login");

    // Uncomment to more easily test redirect logic:
    // return (
    //   <div>
    //     would have redirected to {stashedAppState.returnTo},{" "}
    //     {JSON.stringify(loginData)}
    //   </div>
    // );
    return <Redirect to={afterAuthPath(stashedAppState.returnTo, loginData)} />;
  }

  return (
    <OnboardingLayout>
      <Container>
        <CenteredLoading />
      </Container>
    </OnboardingLayout>
  );
};

export type PreAuthPageProps = {};
const PreAuthPage: React.FC<PreAuthPageProps> = () => {
  return <Auth0Inner />;
};

export default PreAuthPage;
